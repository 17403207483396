<template>
  <div class="box">
    <div class="jackpot">
      <div class="jackpot-title" :data-text="$t('JACKPOT TITLE')">{{ $t("JACKPOT TITLE") }}</div>
      <div class="jackpot-content">
        <img src="@/assets/images/jackpot-left.png" class="jackpot-content-left-cover">
        <div class="jackpot-content-number">
          <span class="jackpot-content-number-text" :data-text="$helper.get('rule').currency.symbol">{{
            $helper.get("rule").currency.symbol }}</span>
          <div v-for="item, key in numFormat(number, false, 0)" :key="key" class="jackpot-content-number-item">
            <div class="jackpot-content-number-item-text" v-if="Number(item) >= 0"
              :style="`transform: translateY(-${item}0%);`">
              <span v-for="item, index in 10" :key="index" class="jackpot-content-number-text" :data-text="index">{{
                index
              }}</span>
            </div>
            <div class="jackpot-content-number-item-text jackpot-content-number-text" v-else :data-text="item">{{ item
              }}
            </div>
          </div>
        </div>
        <img src="@/assets/images/jackpot-right.png" class="jackpot-content-right-cover">
      </div>
    </div>
    <div class="card" @click="$router.push('/all-game?share_code=80736277&tab=27')">
      <img src="@/assets/images/jackpot-trophy.png" class="card-left-icon">
      <scrollRow :min="3" :list="imgList" class="">
        <div v-for="item, index in imgList" :key="index">
          <img :src="item" class="card-img-item">
        </div>
      </scrollRow>
    </div>

  </div>
</template>

<script>
import scrollRow from "@/components/scrollRow.vue";
export default {
  data() {
    return {
      nums: "0123456789",
      interval: null,
      number: this.initNumber(),
      imgList: [
        require("@/assets/images/jackpot-img1.png"),
        require("@/assets/images/jackpot-img2.png"),
        require("@/assets/images/jackpot-img3.png"),
        require("@/assets/images/jackpot-img4.png"),
        require("@/assets/images/jackpot-img5.png"),
        require("@/assets/images/jackpot-img6.png"),
        require("@/assets/images/jackpot-img7.png"),
      ]
    }
  },
  components: {
    scrollRow
  },
  methods: {
    initNumber() {
      const date = new Date()
      const seconds = date.getSeconds() + date.getMinutes() * 60 // 1-3600
      // 设置伪随机输，根据时间线，算出随机数的和
      // 10-1500
      const randomArr = [1230.36, 1120.61, 1850.68, 106.58, 508.36, 458.51, 1890.61, 623.15]
      let num = 0
      for (let i = 0; i < seconds; i++) {
        num += randomArr[i % randomArr.length]
      }
      return Date.now() / 10000 + num
    },
    startRolling() {
      this.interval = setInterval(() => {
        this.number = this.initNumber();
      }, 2000);
    },
  },
  mounted() {
    this.startRolling()
  },
  beforeDestroy() {
    this.interval && clearInterval(this.interval)
  }
}
</script>

<style scoped lang="less" src="@/assets/css/jackpot.less"></style>