<template>
  <el-dialog :visible.sync="isShow" :destroy-on-close="true" custom-class="el-dialog-center2"
    :close-on-click-modal="false" :modal-append-to-body="false">
    <div class="f-box">
      <div class="content">
        <div class="canvas-wapper">
          <div class="canvas">
            <img src="@/assets/images/wufushangceng.png" alt="">
            <div class="canvas-tip">{{ $t("新的呱呱卡请查收") }}</div>
          </div>
        </div>
        <div class="tips">{{ $t("你的好友赠送给你1张新的刮刮卡") }}</div>
        <el-button class="button_fullwidth five-button" @click="$router.push('/five-bessings')">{{ $t("细节")
          }}</el-button>
      </div>
    </div>
    <div class="close-icon" @click="isShow = false">
      <img :src="require('@/assets/images/five-close-icon.png')" alt="">
    </div>
  </el-dialog>
</template>

<script>
import canvasBox from './canvasBox.vue'
export default {
  data() {
    return {
      isShow: false
    }
  },
  components: { canvasBox },
  methods: {
    show() {
      this.isShow = true
    }
  },
  watch: {
    "$route.path": function (v) {
      if (v != "/") {
        this.isShow = false
      }
    }
  },
}
</script>

<style scoped lang="less">
::v-deep .el-dialog {
  background-color: transparent;
  box-shadow: none;
  overflow: visible;
  width: fit-content;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    width: fit-content;
    color: #FFF;
  }
}

.f-box {
  color: #89B6E1;
  user-select: none;
  max-width: 100vw;
  margin: 0 auto;
  background-image: url(~@/assets/images/five-bessings-pop-bg.png);
  background-size: 100% 100%;
  width: 388px;
  padding-bottom: 91%;

  @media (max-width:540px) {
    width: 321px;
  }
}

.five-title {
  width: 100%;
  position: relative;
  z-index: 4;
}

.canvas-wapper {
  width: 100%;
  position: relative;
  padding-bottom: 30%;

  .canvas {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.canvas-tip {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-shadow: 0px 1px 0px rgba(27,77,76,0.83);
  font-weight: bold;
  color: #48A972;
  padding: 0 8px;

  @media (max-width:540px) {
    font-size: 12px;
  }
}

.content {
  width: 80%;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 56%;
  height: 44%;
  transform: translate(-50%)
}

.tips {
  margin: 13px 0;
  font-weight: 500;
  text-align: center;
  color: #DEB964;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width:540px) {
    font-size: 12px;
  }
}

.close-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  top: 18%;
  right: -27px;

  @media (max-width:540px) {
    width: 27px;
    height: 27px;
    right: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.five-button {
  margin-top: 17%;

  @media (max-width:540px) {
    height: 33px;
    display: flex;
    align-items: center;
    font-size: 13px;
  }
}
</style>